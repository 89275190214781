// import * as React from 'react'
// import styles from './styles.module.css'
import { createTheme } from '@mui/material/styles'

// const darkBlue = '#172b53'
// const mediumBlue = '#2c6685'
// const $paleBlue = '#a3c3d4'

// $text-gray: #67696a;
// $disabled-gray: #b0bac1;
// $line-gray: #e2e5e8;
//
// $dark-red: #9f1d22;
// $error-red: #c4401a;
// $hover-red: #a63616;
//
// $success-green: #117b42;
// $hover-green: #0e6838;
//
// $warning-yellow: #efb71f;
// $hover-yellow: #cb9b1a;

export const colors = {
  // primary: {
  //   light: '#ffe958',
  //   main: '#efb71f',
  //   dark: '#b88800',
  //   contrastText: '#000000'
  // },
  primary: {
    light: '#a3c3d4',
    medium: '#357ca2',
    main: '#2c6685',
    dark: '#172b53',
    contrastText: '#000000'
  },
  secondary: {
    light: '#ffffff',
    main: '#ffffff',
    dark: '#cccccc',
    contrastText: '#000000'
  },
  error: {
    light: '#c4401a',
    main: '#a63616',
    dark: '#9f1d22',
    contrastText: '#ffffff'
  },
  success: '#117b42',
  hoverGreen: '#0e6838',
  disabledGrey: '#B0BAC1',
  lineGrey: '#E2E5E8'
}

export const baseTypography = {
  fontSize: 16,
  fontFamily: ['Verdana'].join(','),
  appName: {
    color: colors.error.dark
  },
  h1: {
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: '400',
    color: colors.primary.main
  },
  h2: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '400',
    color: colors.primary.dark
  },
  h3: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '400',
    color: colors.primary.dark
  },
  h4: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '800',
    color: 'black'
  },
  body1: {
    fontSize: '16px'
  },
  htmlFontSize: 20,
  subtitle1: {
    fontSize: '16px',
    fontWeight: 400
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 400
  },
  body2: {
    fontSize: '14px',
    fontWeight: 300
  },
  caption: {
    fontSize: '14px',
    fontWeight: 400
  },
  overline: {
    fontSize: '16px',
    fontWeight: 500
  }
}

export const theme = createTheme({
  typography: baseTypography,
  palette: {
    mode: 'light',
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
      contrastText: colors.primary.contrastText
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
      contrastText: colors.secondary.contrastText
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: colors.error.contrastText
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(97, 97, 97, 0.92)'
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        // size: 'small'
      },
      styleOverrides: {
        root: {
          '.Mui-disabled': {
            backgroundColor: '#eee'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '16px !important'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        size: 'small',
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          paddingTop: '11px',
          paddingBottom: '11px',
          paddingRight: '8px',
          fontSize: '16px',
          lineHeight: '24px',
          borderRadius: '3px',
          textTransform: 'none',
          minWidth: '48px',
          height: '48px',
          '& .MuiButton-startIcon': {
            margin: '0 4px',
            '& .MuiSvgIcon-root': {
              fontSize: '24px'
            }
          }
        },
        contained: {
          backgroundColor: colors.primary.medium,
          boxShadow: 'none',
          color: '#fff',
          '& .MuiButton-startIcon svg': {
            color: 'white'
          },
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.primary.main,
            '& .MuiButton-startIcon svg': {
              color: 'white'
            }
          },
          '&.success': {
            backgroundColor: colors.success,
            '&:hover': {
              backgroundColor: colors.hoverGreen
            }
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          outline: `1px solid ${colors.primary.main}`,
          border: 'none',
          padding: '8px',
          backgroundColor: 'white',
          textTransform: 'none',
          color: colors.primary.main,
          '& .MuiSvgIcon-root': {
            color: colors.primary.main,
            fontSize: '24px'
          },
          '&:hover': {
            backgroundColor: colors.primary.light,
            '& .MuiSvgIcon-root': {
              color: colors.primary.dark
            }
          },
          '&.Mui-selected': {
            color: 'black',
            backgroundColor: colors.primary.light,
            '& .MuiSvgIcon-root': {
              color: 'black'
            },
            '&:hover': {
              backgroundColor: colors.primary.light
            }
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-toolbarContainer': {
            '@media only screen and (max-width: 600px)': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              '.MuiButton-root': {
                width: '100%',
                padding: '0px 4px',
                margin: '4px 4px'
              }
            },
            padding: '8px',
            '.MuiButton-root': {
              marginRight: '16px'
            }
          },
          '.MuiDataGrid-columnsContainer': {
            '.MuiDataGrid-menuIcon': {
              visibility: 'visible'
            }
          },
          '.MuiDataGrid-row:hover': {
            backgroundColor: colors.secondary.dark
          },
          '.MuiDataGrid-row.Mui-even': {
            backgroundColor: colors.primary.dark
          }
        }
      }
    },
    MuiFormGroup: {
      defaultProps: {
        row: true
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.secondary.main
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          color: 'black',
          lineHeight: '24px',
          height: 'auto',
          whiteSpace: 'normal',
          background: 'white',
          // padding: theme.(1spacing),
          // borderRadius: customTheme.spacing(1.5),
          boxShadow: `0 0 0 1px ${colors.lineGrey}`,
          '& .MuiChip-label': {
            // padding: customTheme.spacing(0),
            whiteSpace: 'normal'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        // root: {
        //   fontWeight: 'normal'
        // }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: `none`,
          '.MuiInputBase-root': {
            color: 'white',
            border: '1px solid white'
          },
          '.MuiSelect-icon': {
            color: 'white'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.lineGrey}`,
          '&.MuiAccordion-root:before': {
            background: 'none'
          },
          '&.MuiAccordion-root.Mui-expanded': {
            margin: 0
          },
          '&.MuiPaper-elevation1': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '10px 0'
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          height: '56px',
          padding: '16px',
          borderBottom: '1px solid' + colors.lineGrey,
          lineHeight: '24px',
          '.mat-icon': {
            color: 'black',
            fontsize: '24px',
            marginRight: '8px'
          },
          '.MuiListItemText-root, .MuiListItemIcon-root': {
            color: 'black'
          },
          '&:hover': {
            backgroundColor: colors.primary.light,
            '.MuiListItemText-root, .MuiListItemIcon-root': {
              color: colors.primary.main
            }
          },
          '&.selected': {
            backgroundColor: colors.primary.light,
            '.MuiListItemText-root, .MuiListItemIcon-root': {
              color: 'black'
            }
          }
        }
      }
    }
  }
})
