import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import LaptopIcon from '@mui/icons-material/Laptop'
import TvIcon from '@mui/icons-material/Tv'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import Grid from '@mui/material/Grid'

export default function ColorToggleButton() {
  const [device, setDevice] = React.useState('phone')

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    device: string
  ) => {
    console.log('*** event ' + event)
    setDevice(device)
  }

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          color='primary'
          value={device}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value='laptop'>laptop</ToggleButton>
          <ToggleButton value='tv'>tv</ToggleButton>
          <ToggleButton value='phone'>phone</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={device}
          exclusive
          onChange={handleChange}
          aria-label='device'
        >
          <ToggleButton value='laptop' aria-label='laptop'>
            <LaptopIcon />
          </ToggleButton>
          <ToggleButton value='tv' aria-label='tv'>
            <TvIcon />
          </ToggleButton>
          <ToggleButton value='phone' aria-label='phone'>
            <PhoneAndroidIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  )
}
