import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import InfoIcon from '@mui/icons-material/Info'

export default function IconButtons() {
  return (
    <Stack direction='row' spacing={1}>
      <IconButton>
        <InfoIcon />
      </IconButton>
      <IconButton disabled color='primary'>
        <InfoIcon />
      </IconButton>
      <IconButton color='primary'>
        <InfoIcon />
      </IconButton>
    </Stack>
  )
}
