import * as React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import FaceIcon from '@mui/icons-material/Face'

export default function BasicChips() {
  return (
    <Stack direction='column' spacing={1}>
      <Stack direction='row' spacing={1}>
        <Chip label='Chip Filled' />
        <Chip label='Chip Outlined' variant='outlined' />
      </Stack>
      <Stack direction='row' spacing={1}>
        <Chip icon={<FaceIcon />} label='With Icon' />
        <Chip icon={<FaceIcon />} label='With Icon' variant='outlined' />
      </Stack>
      <Stack direction='row' spacing={1}>
        <Chip label='primary' color='primary' />
        <Chip label='success' color='success' />
      </Stack>
      <Stack direction='row' spacing={1}>
        <Chip label='primary' color='primary' variant='outlined' />
        <Chip label='success' color='success' variant='outlined' />
      </Stack>
    </Stack>
  )
}
