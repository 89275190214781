import React from 'react'

import { theme } from 'react-theme'
import { ThemeProvider } from '@mui/material/styles'
import IndeterminateCheckbox from './components/Checkboxes'
import BasicSelect from './components/Selects'
import { Button, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import PrimarySearchAppBar from './components/AppBar'
import Types from './components/types'
import Inputs from './components/Inputs'
import { styled } from '@mui/system'
import BasicTable from './components/BasicTable'
import SimpleSnackbar from './components/Snackbar'
import RowRadioButtonsGroup from './components/RadioButtons'
import BasicSwitches from './components/Switch'
import BasicTabs from './components/Tabs'
import Box from '@mui/material/Box'
import CircularIndeterminate from './components/CircularIndeterminate'
import BasicChips from './components/BasicChips'
import SliderSizes from './components/SliderSizes'
import MultipleSelectCheckmarks from './components/MultipleSelectCheckmarks'
import BasicTooltip from './components/BasicTooltip'
import VerticalLinearStepper from './components/verticalLinearStepper'
import SimpleAccordion from './components/SimpleAccordion'
import HorizontalLinearStepper from './components/HorizontalLinearStepper'
import ColorToggleButton from './components/Toggle'
import IconButtons from './components/IconButtons'
import IconMenu from './components/IconMenu'

const ImplementationSample = styled('div')({
  borderRadius: '3px',
  border: '1px solid #E2E5E8',
  padding: '16px',
  h2: {
    marginBottom: '32px'
  }
})

const ColorSample = styled('div')({
  width: '100px',
  height: '100px',
  margin: '16px',
  borderRadius: '3px'
})

const ColorSamplePrimaryLight = styled(ColorSample)(
  ({ theme }) => `background-color: ${theme.palette.primary.light};`
)
const ColorSamplePrimaryMain = styled(ColorSample)(
  ({ theme }) => `background-color: ${theme.palette.primary.main};`
)
const ColorSamplePrimaryDark = styled(ColorSample)(
  ({ theme }) => `background-color: ${theme.palette.primary.dark};`
)

const ColorSampleErrorLight = styled(ColorSample)(
  ({ theme }) => `background-color: ${theme.palette.error.light};`
)
const ColorSampleErrorMain = styled(ColorSample)(
  ({ theme }) => `background-color: ${theme.palette.error.main};`
)
const ColorSampleErrorDark = styled(ColorSample)(
  ({ theme }) => `background-color: ${theme.palette.error.dark};`
)

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <PrimarySearchAppBar />
      <Container maxWidth='lg'>
        <Grid container spacing={2} mt={3}>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>typography</Typography>
              <Types />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>colors</Typography>
              <Typography variant='h4'>primary</Typography>
              <Box sx={{ display: 'flex' }}>
                <ColorSamplePrimaryLight />
                <ColorSamplePrimaryMain />
                <ColorSamplePrimaryDark />
              </Box>
              <Typography variant='h4'>error</Typography>
              <Box sx={{ display: 'flex' }}>
                <ColorSampleErrorLight />
                <ColorSampleErrorMain />
                <ColorSampleErrorDark />
              </Box>
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>table</Typography>
              <BasicTable />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>inputs</Typography>
              <Inputs />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>buttons</Typography>
              <Button variant='text'>Text</Button>
              <Button variant='contained'>Contained</Button>
              <Button variant='outlined'>Outlined</Button>
              <Typography variant='h2'>toggle</Typography>
              <ColorToggleButton />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>radio button</Typography>
              <RowRadioButtonsGroup />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>checkbox</Typography>
              <IndeterminateCheckbox />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>icons</Typography>
              <IconButtons />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>tabs panel</Typography>
              <BasicTabs />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>slide toggle</Typography>
              <BasicSwitches />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>dropdown</Typography>
              <BasicSelect />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>
                dropdown with multiple selection
              </Typography>
              <MultipleSelectCheckmarks />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>chip/pill</Typography>
              <BasicChips />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>progress indicator</Typography>
              <CircularIndeterminate />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>slider</Typography>
              <SliderSizes />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>datepicker</Typography>
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>menu</Typography>
              <IconMenu />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>expansion panel</Typography>
              <SimpleAccordion />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>sidenav</Typography>
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>snackbar</Typography>
              <SimpleSnackbar />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>dialog</Typography>
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>tooltip</Typography>
              <BasicTooltip />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>horizontal stepper</Typography>
              <HorizontalLinearStepper />
            </ImplementationSample>
          </Grid>
          <Grid item xs={6}>
            <ImplementationSample>
              <Typography variant='h2'>vertical stepper</Typography>
              <VerticalLinearStepper />
            </ImplementationSample>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

export default App
